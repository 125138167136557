import './App.css';
import { Routes, Route, Outlet } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Projects from './pages/Projects';
import PageNotFound from './pages/PageNotFound';
import { LanguageProvider } from './LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='projects' element={<Projects />} />
          <Route path='contact' element={<Contact />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </LanguageProvider>
  );
}

function Layout() {
  return (
    <div className="App">
      <NavBar />
      <div className='content'>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
