import React, { useContext } from 'react';
import profilePic from '../images/my_image.jpg';
import { LanguageContext } from '../LanguageContext';
import '../Styles/About.css';

const About = () => {
  const { language, translations } = useContext(LanguageContext);

  return (
    <div className="about-me">
      <div className="profile-banner">
        <img src={profilePic} alt="Zen Zalapski" className="profile-pic" />
      </div>
      <h1>{translations[language].aboutMe}</h1>
      <div className="intro">
        <p>{translations[language].aboutText}</p>
      </div>
      <h2>{translations[language].professionalBackground}</h2>
      <p>{translations[language].professionalBackgroundText}</p>
      <h2>{translations[language].skillsTechnologies}</h2>
      <ul>
        <li>Java</li>
        <li>JavaScript</li>
        <li>React.js</li>
        <li>Python</li>
        <li>SQL and NoSQL</li>
      </ul>
      <h2>{translations[language].personalProjectsInterests}</h2>
      <p>{translations[language].personalProjectsInterestsText}</p>
      <h2>{translations[language].goalsAmbitions}</h2>
      <p>{translations[language].goalsAmbitionsText}</p>
      <h2>{translations[language].contact}</h2>
      <p>{translations[language].contactText}</p>
    </div>
  );
};

export default About;
