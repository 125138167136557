import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import profilePic from '../images/my_image.jpg';
import { LanguageContext } from '../LanguageContext';
import '../Styles/Home.css';

const Home = () => {
    const { language, translations } = useContext(LanguageContext);

    return (
      <div className="home">
        <header className="home-header">
          <div className="banner">
            <div className="profile-container">
              <img src={profilePic} alt="Zen Zalapski" className="profile-pic" />
            </div>
          </div>
          <div className="header-text">
            <h1>Zen Zalapski - {language === 'en' ? 'Software Developer' : 'Softwareentwickler'}</h1>
            <p>{language === 'en' ? 'Transforming Ideas into Digital Reality' : 'Verwandle Ideen in digitale Realität'}</p>
          </div>
        </header>
        <section className="intro">
          <h2>{translations[language].aboutMe}</h2>
          <p>
            {language === 'en'
              ? `I am a passionate Software Developer that is still learning the ropes. I have a strong background in Java, JavaScript, React.js, and Python. I thrive on solving complex problems and building my own solutions.`
              : `Ich bin ein leidenschaftlicher Softwareentwickler, der noch dabei ist, die Grundlagen zu erlernen. Ich habe einen starken Hintergrund in Java, JavaScript, React.js und Python. Ich blühe auf, wenn es darum geht, komplexe Probleme zu lösen und meine eigenen Lösungen zu entwickeln.`}
          </p>
          <Link to="/about" className="btn">{language === 'en' ? 'More' : 'Mehr'}</Link>
        </section>
        <section className="projects">
          <h2>{language === 'en' ? 'My Projects' : 'Meine Projekte'}</h2>
          <div className="project-list">
            <div className="project-item">
              <h3>{language === 'en' ? 'Application Tracker' : 'Bewerbungs Tracker'}</h3>
              <p>{language === 'en' ? 'A web application developed using React.js and Spring boot that helps tracking Applications.' : 'Eine Webanwendung, die mit React.js und Spring Boot entwickelt wurde und beim Verfolgen von Anwendungen hilft.'}</p>
              <Link to="/projects" className="btn">{language === 'en' ? 'More' : 'Mehr'}</Link>
            </div>
            <div className="project-item">
              <h3>{language === 'en' ? 'Game of Life' : 'Spiel des Lebens'}</h3>
              <p>{language === 'en' ? 'A Java application developed that simulates the Game of Life.' : 'Eine in Java entwickelte Anwendung, die das Spiel des Lebens simuliert.'}</p>
              <Link to="/projects" className="btn">{language === 'en' ? 'More' : 'Mehr'}</Link>
            </div>
            <div className="project-item">
              <h3>{language === 'en' ? 'Pomodoro' : 'Pomodoro'}</h3>
              <p>{language === 'en' ? 'A Python application developed using Tkinter.' : 'Eine mit Tkinter entwickelte Python-Anwendung.'}</p>
              <Link to="/projects" className="btn">{language === 'en' ? 'More' : 'Mehr'}</Link>
            </div>
          </div>
        </section>
        <section className="skills">
          <h2>{translations[language].skillsTechnologies}</h2>
          <ul className="skills-list">
            <li>Java</li>
            <p>{language === 'en' ? 'Created API endpoints with Spring and classical projects such as Game of Life, Knapsack and Snake.' : 'Erstellt API-Endpunkte mit Spring und klassische Projekte wie Game of Life, Knapsack und Snake.'}</p>
            <li>JavaScript</li>
            <p>{language === 'en' ? 'Basic single page web Applications such as a todo List and small Websites.' : 'Grundlegende Single-Page-Webanwendungen wie eine To-do-Liste und kleine Websites.'}</p>
            <li>React.js</li>
            <p>{language === 'en' ? 'Used React in combination with Java Spring Boot to create full-stack Applications.' : 'Verwendet React in Kombination mit Java Spring Boot, um Full-Stack-Anwendungen zu erstellen.'}</p>
            <li>Python</li>
            <p>{language === 'en' ? 'Simple scripts to solve repetitive tasks. Used libraries/frameworks such as Pandas, Tkinter, Selenium, and many more.' : 'Einfache Skripte zur Lösung sich wiederholender Aufgaben. Verwendete Bibliotheken/Frameworks wie Pandas, Tkinter, Selenium und viele mehr.'}</p>
          </ul>
        </section>
        <section className="contact">
          <h2>{translations[language].contact}</h2>
          <p>{language === 'en' ? 'Have a project in mind? Let\'s talk!' : 'Haben Sie ein Projekt im Sinn? Lassen Sie uns reden!'}</p>
          <Link to="/contact" className="btn">{language === 'en' ? 'Get in Touch' : 'Kontakt aufnehmen'}</Link>
        </section>
      </div>
    );
  };
  
  export default Home;
