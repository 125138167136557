import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../LanguageContext';
import '../Styles/NavBar.css';

const NavBar = () => {
  const { language, switchLanguage } = useContext(LanguageContext);

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item"><Link to="/" className="navbar-link">{language === 'en' ? 'Home' : 'Startseite'}</Link></li>
        <li className="navbar-item"><Link to="/about" className="navbar-link">{language === 'en' ? 'About Me' : 'Über Mich'}</Link></li>
        <li className="navbar-item"><Link to="/projects" className="navbar-link">{language === 'en' ? 'Projects' : 'Projekte'}</Link></li>
        <li className="navbar-item"><Link to="/contact" className="navbar-link">{language === 'en' ? 'Contact' : 'Kontakt'}</Link></li>
        <li className="navbar-item language-switch">
          <button onClick={() => switchLanguage('en')} className={`language-button ${language === 'en' ? 'active' : ''}`}>EN</button>
          <button onClick={() => switchLanguage('de')} className={`language-button ${language === 'de' ? 'active' : ''}`}>DE</button>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
