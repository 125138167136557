import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import '../Styles/Contact.css';

const Contact = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="contact">
      <h1>{language === 'en' ? 'Contact Me' : 'Kontaktieren Sie mich'}</h1>
      <p>{language === 'en' ? 'Feel free to reach out for collaboration, questions about my projects, or any professional inquiries.' : 'Zögern Sie nicht, sich für eine Zusammenarbeit, Fragen zu meinen Projekten oder berufliche Anfragen zu melden.'}</p>
      <div className="contact-info">
        <h2>{language === 'en' ? 'Direct Contact Information' : 'Direkte Kontaktinformationen'}</h2>
        <p>Email: <a href="mailto:zenzz@hotmail.ch">zenzz@hotmail.ch</a></p>
        <p>LinkedIn: <a href="https://www.linkedin.com/in/zen-zalapski-7a35052b9/" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/in/zen-zalapski</a></p>
        <p>GitHub: <a href="https://github.com/HollowWeb" target="_blank" rel="noopener noreferrer">https://github.com/HollowWeb</a></p>
        <p>{language === 'en' ? 'Location: Zurich, Switzerland' : 'Standort: Zürich, Schweiz'}</p>
      </div>
      <div className="contact-note">
        <p>{language === 'en' ? 'I usually respond within 24 hours. Your information will be kept confidential and not shared with third parties.' : 'Ich antworte in der Regel innerhalb von 24 Stunden. Ihre Informationen werden vertraulich behandelt und nicht an Dritte weitergegeben.'}</p>
      </div>
    </div>
  );
};

export default Contact;
