import React, { createContext, useState } from 'react';

export const LanguageContext = createContext();

const translations = {
  en: {
    aboutMe: "About Me",
    professionalBackground: "Professional Background",
    skillsTechnologies: "Skills and Technologies",
    personalProjectsInterests: "Personal Projects and Interests",
    goalsAmbitions: "Goals and Ambitions",
    contact: "Contact",
    aboutText: `Hi, I'm Zen Zalapski, a passionate 24-year-old Computer Science student with a strong background in Java, JavaScript, React.js, and Python. I am always on the lookout for new ideas and problems that I can solve with my own applications. I thrive on solving complex problems and continuously learning new technologies. 
    Midway through my tenth school year, I received an opportunity to work as a certified mason (EFZ), which aligned well with my immediate goals. After three challenging years, I successfully earned my EFZ certification. In January of the following year, I was called up for military service and joined the infantry, where I also specialized in demolition.
    After completing my compulsory military service, I found myself at a crossroads, unsure of my career path. I spent two years working as a temporary construction worker with various companies. During this time, I gradually lost my enthusiasm for the profession. It was in the first year of this job that I discovered my interest in programming. I started watching YouTube videos to teach myself how to code, choosing Python as my first programming language due to its resemblance to English and its reputation as one of the easiest languages to learn.
    Despite my initial progress, I encountered challenges. After finishing a few tutorials, I hit a roadblock and took a short break. A month later, I tried to resume my learning but realized I had forgotten most of what I had learned. Determined, I then decided to enroll at WISS for retraining as an IT application developer.
    My journey from construction to computer science has been unconventional but rewarding. It has fueled my passion for technology and problem-solving, and I am now committed to further developing my skills and knowledge in this dynamic field.`,
    professionalBackgroundText: `I hold an EFZ degree as a Construction site expert from Baugewerbliche Berufsschule Zürich and have worked on various projects where I learned how to work, solve problems and take responsibility for small teams. Now that I am 24-years-old, I have chosen to take a new path where I can use my problem-solving skills to the fullest, currently attending WISS in Zürich as a Computer Scientist Student. At school and in my free time, I have made major progress toward achieving my goal of becoming the best I can be.`,
    personalProjectsInterestsText: `In my free time, I enjoy working on personal projects such as developing an Application Tracker or single-use scripts that take care of repetitive tasks. I am also an avid leet-coder and love exploring new programming languages, technologies, and concepts.`,
    goalsAmbitionsText: `My short-term goal is to deepen my knowledge in programming and find an internship to work on bigger projects, learn and network. Long-term, I want to finish my degree top of the class and then work on solutions that make changes in the world.`,
    contactText: `Feel free to get in touch if you'd like to collaborate on a project or just want to network.`,
    contactMe: "Contact Me",
    contactInfo: "Direct Contact Information",
    contactNote: "I usually respond within 24 hours. Your information will be kept confidential and not shared with third parties.",
  },
  de: {
    aboutMe: "Über Mich",
    professionalBackground: "Beruflicher Hintergrund",
    skillsTechnologies: "Fähigkeiten und Technologien",
    personalProjectsInterests: "Persönliche Projekte und Interessen",
    goalsAmbitions: "Ziele und Ambitionen",
    contact: "Kontakt",
    aboutText: `Hallo, ich bin Zen Zalapski, ein leidenschaftlicher 24-jähriger Informatikstudent mit einem starken Hintergrund in Java, JavaScript, React.js und Python. Ich bin immer auf der Suche nach neuen Ideen und Problemen, die ich mit meinen eigenen Anwendungen lösen kann. Ich gedeihe darin, komplexe Probleme zu lösen und kontinuierlich neue Technologien zu erlernen. 
    Mitte meines zehnten Schuljahres erhielt ich die Möglichkeit, als zertifizierter Maurer (EFZ) zu arbeiten, was gut zu meinen unmittelbaren Zielen passte. Nach drei herausfordernden Jahren erwarb ich erfolgreich mein EFZ-Zertifikat. Im Januar des folgenden Jahres wurde ich zum Militärdienst einberufen und trat der Infanterie bei, wo ich mich auch auf Sprengungen spezialisierte.
    Nach Abschluss meines Wehrdienstes stand ich an einem Scheideweg und war mir unsicher über meinen beruflichen Weg. Ich arbeitete zwei Jahre lang als temporärer Bauarbeiter bei verschiedenen Unternehmen. Während dieser Zeit verlor ich allmählich meine Begeisterung für den Beruf. Im ersten Jahr dieser Tätigkeit entdeckte ich mein Interesse am Programmieren. Ich begann, YouTube-Videos anzusehen, um mir selbst das Programmieren beizubringen, und wählte Python als meine erste Programmiersprache, da es dem Englischen ähnelt und als eine der einfachsten Sprachen gilt.
    Trotz meiner anfänglichen Fortschritte stieß ich auf Herausforderungen. Nachdem ich einige Tutorials abgeschlossen hatte, stieß ich auf eine Blockade und legte eine kurze Pause ein. Einen Monat später versuchte ich, mein Lernen wieder aufzunehmen, stellte jedoch fest, dass ich das meiste, was ich gelernt hatte, vergessen hatte. Entschlossen, habe ich dan die entscheidung getroffen mich bei der WISS für die Umschulung zum Informatiker Applikationsentwickler anzumelden.
    Mein Weg vom Bauwesen zur Informatik war unkonventionell, aber lohnend. Er hat meine Leidenschaft für Technologie und Problemlösung befeuert, und ich bin jetzt bestrebt, meine Fähigkeiten und mein Wissen in diesem dynamischen Bereich weiterzuentwickeln.`,
    professionalBackgroundText: `Ich habe ein EFZ als Maurer von der Baugewerblichen Berufsschule Zürich und habe an verschiedenen Projekten gearbeitet, bei denen ich gelernt habe, wie man arbeitet, Probleme löst und Verantwortung für kleine Teams übernimmt. Jetzt, da ich 24 Jahre alt bin, habe ich mich entschieden, einen neuen Weg einzuschlagen, bei dem ich meine Problemlösungsfähigkeiten voll ausschöpfen kann. Derzeit besuche ich die WISS in Zürich als Informatikstudent. In der Schule und in meiner Freizeit habe ich große Fortschritte gemacht, um mein Ziel zu erreichen, der Beste zu werden.`,
    personalProjectsInterestsText: `In meiner Freizeit arbeite ich gerne an persönlichen Projekten wie der Entwicklung eines Anwendungstrackers oder Einzelskripten, die sich um sich wiederholende Aufgaben kümmern. Ich bin auch ein begeisterter Leet-Coder und liebe es, neue Programmiersprachen, Technologien und Konzepte zu erkunden.`,
    goalsAmbitionsText: `Mein kurzfristiges Ziel ist es, mein Wissen im Programmieren zu vertiefen und ein Praktikum zu finden, um an größeren Projekten zu arbeiten, zu lernen und zu netzwerken. Langfristig möchte ich meinen Abschluss als Jahrgangsbester abschließen und dann an Lösungen arbeiten, die Veränderungen in der Welt bewirken.`,
    contactText: `Fühlen Sie sich frei, sich zu melden, wenn Sie an einer Zusammenarbeit bei einem Projekt interessiert sind oder einfach nur netzwerken möchten.`,
    contactMe: "Kontaktieren Sie mich",
    contactInfo: "Direkte Kontaktinformationen",
    contactNote: "Ich antworte in der Regel innerhalb von 24 Stunden. Ihre Informationen werden vertraulich behandelt und nicht an Dritte weitergegeben.",
  }
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};
