import React from "react";
import '../Styles/PageNotFound.css'
const PageNotFound = () => {
    return (
      <div className="page-not-found">
        <h1>404</h1>
        <p>The page you are looking for does not exist.</p>
        <a href="/" className="btn">Go Home</a>
      </div>
    );
  };
  
  export default PageNotFound;