import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageContext';
import '../Styles/Projects.css';

import applicationTrackerImg from '../images/application_tracker2.png';
import gameOfLifeImg from '../images/game_of_life.png';
import pomodoroImg from '../images/pomodoro.png';

const projects = {
  en: [
    {
      name: 'Application Tracker',
      description: 'A web application developed using React.js and Java to Track Applications.',
      technologies: ['React.js', 'Spring Boot', 'SQL'],
      link: 'https://github.com/HollowWeb/Application-Tracker',
      image: applicationTrackerImg
    },
    {
      name: 'Game of Life',
      description: 'A Java application developed that simulates the Game of Life using JFrame from the Swing library.',
      technologies: ['Java', 'OOP', 'JFrame'],
      link: 'https://github.com/HollowWeb/Game-of-life',
      image: gameOfLifeImg
    },
    {
      name: 'Pomodoro',
      description: 'A Python application developed using Tkinter. To help me time my Work and breaks.',
      technologies: ['Python', 'Tkinter'],
      link: 'https://github.com/HollowWeb/Pomodoro',
      image: pomodoroImg
    },
  ],
  de: [
    {
      name: 'Bewerbungs Tracker',
      description: 'Eine Webanwendung, die mit React.js und Java entwickelt wurde, um Bewerbungen zu verfolgen.',
      technologies: ['React.js', 'Spring Boot', 'SQL'],
      link: 'https://github.com/HollowWeb/Application-Tracker',
      image: applicationTrackerImg
    },
    {
      name: 'Spiel des Lebens',
      description: 'Eine Java-Anwendung, die das Spiel des Lebens mit JFrame aus der Swing-Bibliothek simuliert.',
      technologies: ['Java', 'OOP', 'JFrame'],
      link: 'https://github.com/HollowWeb/Game-of-life',
      image: gameOfLifeImg
    },
    {
      name: 'Pomodoro',
      description: 'Eine mit Tkinter entwickelte Python-Anwendung, um meine Arbeit und Pausen zu planen.',
      technologies: ['Python', 'Tkinter'],
      link: 'https://github.com/HollowWeb/Pomodoro',
      image: pomodoroImg
    },
  ]
};

const Projects = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="projects">
      <h1>{language === 'en' ? 'My Projects' : 'Meine Projekte'}</h1>
      <p>{language === 'en' ? "Here are some of the projects I've worked on recently." : 'Hier sind einige der Projekte, an denen ich kürzlich gearbeitet habe.'}</p>
      <div className="project-list">
        {projects[language].map((project, index) => (
          <div key={index} className="project-items">
            <h2>{project.name}</h2>
            <p>{project.description}</p>
            <p><strong>{language === 'en' ? 'Technologies:' : 'Technologien:'}</strong> {project.technologies.join(', ')}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer">{language === 'en' ? 'View on GitHub' : 'Auf GitHub ansehen'}</a>
            <img src={project.image} alt={project.name} className="project-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
